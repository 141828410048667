import styled, {createGlobalStyle} from 'styled-components';
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';

export const StylesWelcome = createGlobalStyle`
  
`
export const ContWelcome = styled.div`
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: ${GlobalBackgroundColors.bgPrimary};

  @media(min-width: 300px) and (max-width: 767px){
    height: auto;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: auto;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    height: auto;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    height: auto;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    height: calc(100vh - (90px + 50px))
  }
  @media(min-width: 1920px){
    height: calc(100vh - (90px + 50px));
  }
`
export const ContImgCover = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ContPlanDetails = styled.div`
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 90%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 80%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 80%;
  }
  @media(min-width: 1920px){
    width: 80%;
  }
`
export const ContTitleWelcome = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`
export const TextWelcome = styled.h1`
  font: 36px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  color: ${GlobalColors.colorPrimary};
`
export const TextName = styled.div`
  font: 52px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 800;
  line-height: 52px;
  color: ${GlobalColors.colorSecondary};
`
export const TextMessage = styled.div`
  font: 18px ${GlobalFonts.fontBold};
  font-weight: 600;
  line-height: 24px;
  text-align: flex-start;
  color: ${GlobalColors.colorText};
`
export const ContPlanDetailsItems = styled.div`
  margin: 25px auto;
  
  @media(min-width: 300px) and (max-width: 1023px){
    width: 100%;
    margin: 25px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  @media(min-width: 1024px){
    width: 100%;
    margin: 80px 10px;
  }
`
export const ContPlanDetailsItem = styled.div`
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`
export const ContPlanDetailsItemIco = styled.div`
  width: 35px;
  height: 35px;
  margin-right: 15px;
`
export const ContPlanDetailsItemInfo = styled.div`
  font: 18px ${GlobalFonts.fontBold};
  font-weight: 600;
  line-height: 24px;
  //text-align: center;
  color: ${GlobalColors.colorText};
`
export const ContServicesDetailItem = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`
export const ContServicesDetailItemIco = styled.div`
  width: 33px;
  height: 33px;
  margin-right: 15px;
  cursor: pointer;
`
export const ContServicesDetailItemInfo = styled.div`
  width: 84%;
  height: auto;
  font: 18px ${GlobalFonts.fontRegular}; 
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${GlobalColors.colorText};
`;
