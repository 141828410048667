import * as React from "react"
import Layout from '../components/Layout'
import SEO from "../components/seo"
import {WelcomeComp} from '../components/Welcome';

const BienvenidaPage = () => {

  return (
    <Layout clsOverflow={false} typeRouteMenu={'/#'}>
      <SEO
        title=""
        description=""
        works=''
        bolImage='1'
        ogImage={`home_f.jpg`}
        twitterImage={`home_t.jpg`}
        canonicalUrl="https://www.callmatik.com/welcome"
      />

      <WelcomeComp/>

    </Layout>
  )
}

export default BienvenidaPage
