import React, {useEffect, useState} from 'react';
import {Column, Columns, Container} from 'bloomer';
import {
  ContWelcome,
  ContImgCover,
  StylesWelcome,
  ContPlanDetails,
  ContTitleWelcome,
  TextWelcome,
  TextName,
  TextMessage,
  ContPlanDetailsItems,
  ContPlanDetailsItem, ContPlanDetailsItemIco, ContPlanDetailsItemInfo,
  ContServicesDetailItem, ContServicesDetailItemIco,
  ContServicesDetailItemInfo,
} from './styles';
import {ABtnPrimary, BtnPrimary, GlobalStyle} from '../../styles/GlobalStyles';
import CoverWelcome from '../../images/cover-welcome.png';
import IconPhone from '../../images/ico-phone-secondary.svg';
import IconBell from '../../images/ico-bell-secondary.svg';
import IconPlus from '../../images/ico-plus-secondary.svg';
import IconChat from '../../images/ico-chat-secondary.svg';
import {TransitionState} from 'gatsby-plugin-transition-link';
import imgPayment from './spei_brand.png';
import './spei.css';
import imgPaymentOxxo from './oxxopay_brand.png';
import './oxxo.css';

export const WelcomeComp = (goTo = '') => {
  const [firstLoad, setFirstLoad] = useState(false);
  const [stateAux, setStateAux] = useState({});
  //const component = '';
  console.log(stateAux, 'stateAux');
  const [services, setServices] = useState([
    {
      label: '100 llamadas entrantes'
    },
    {
      label: '100 notificaciones'
    },
    {
      label: '10 agendas'
    },
    {
      label: 'Llamada adicional: $16.00 MXN'
    },
    {
      label: 'Agenda adicional: $19.00 MXN'
    },
  ]);

  useEffect(() => {
    // Check if window is defined (so if in the browser or in node.js).
    const isBrowser = typeof window && localStorage !== "undefined";
    console.log('isBrowser=>', isBrowser);
    if (isBrowser) {
    localStorage.removeItem('callmatik_personal_data');
    localStorage.removeItem('callmatik_invoice');
    localStorage.removeItem('callmatik_plan');
    console.log(stateAux, 'stateAux')
    setStateAux(window?.history?.state);
    setFirstLoad(true);
    console.log(stateAux, 'stateAux')

    const fetchServices = () => {
      const services2 = window?.history?.state?.stService;
      if (services2 != null) {
        const services2Array = Object.values(services2)
          .filter(item => typeof item === 'object' && item.hasOwnProperty('label'));
        if (services2Array.length > 0) {
          setServices(services2Array);
          return;
        }
      } 
  
      // Si no se encontraron servicios en services2, intentar obtenerlos de localStorage
      const services3 = localStorage.getItem('myServices');
      if (services3) {
        const parsedServices3 = JSON.parse(services3);
        if (parsedServices3 && parsedServices3.length > 0) {
          setServices(parsedServices3);
          return;
        }
      }
    };
    fetchServices();
    }
  }, []);
  
  return (
    <ContWelcome>
      <GlobalStyle/>
      <StylesWelcome/>
      <Container >
        <Columns className={'columns-reorder-mobile'}>
          <Column className={'center-column'} isSize={{mobile: 12, tablet: 12, desktop: 7, widescreen: 7}}>
              <ContImgCover>
                <img src={CoverWelcome} alt={'Callmatik'}/>
              </ContImgCover> 
          </Column>
          <Column className={'form-registration'} isSize={{mobile: 12, tablet: 12, desktop: 5, widescreen: 5}}>
            <ContPlanDetails>
              <ContTitleWelcome>
                <TextWelcome>¡Bienvenido! 👋</TextWelcome>
                <TextName>{stateAux?.name}</TextName>
              </ContTitleWelcome>
              <TextMessage>
                Nos alegra que te hayas unido a Callmatik, ahora puedes disfrutar de los beneficios de tu membresía, 
                recuerda que puedes cancelar en cualquier momento.
              </TextMessage>
              <ContPlanDetailsItems>
                {services.map((item, index) => {
                  const icon = index === 0 ? IconPhone : index === 1 ? IconBell : IconPlus;
                  return (
                    <ContServicesDetailItem key={index}>
                      <ContServicesDetailItemIco>
                        <img src={icon} alt={''}/>
                      </ContServicesDetailItemIco>
                      <ContServicesDetailItemInfo>{item.label}</ContServicesDetailItemInfo>
                    </ContServicesDetailItem>
                  );
                })}
              </ContPlanDetailsItems>
              <ContPlanDetailsItems>
                <ContTitleWelcome>
                
                <TextMessage>
                Para establecer su constraseña privada, y elegir su número Callmatik.
                </TextMessage>
                <br></br>
                <ABtnPrimary
                  /*cover
                  bg={GlobalBackgroundColors.bgContentLight}*/
                  // to={'/'}
                  to={stateAux.redirect}
                  /*exit={{length: 0.5, state: {plan: name, title: title, price: price}}}
                  entry={{delay: 0.5, state: {plan: name, title: title, price: price}}}
                  state={{plan: name, title: title, price: price}}*/
                >
                  Configurar mi servicio
                </ABtnPrimary>
                </ContTitleWelcome>
              </ContPlanDetailsItems>
            </ContPlanDetails>
          </Column>
        </Columns>
      </Container>
    </ContWelcome>
  );
};
